import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/Header.css";
import academica from "../assets/img/academica.png";
import pegasus from "../assets/img/logo-pegasus.png";

const Header: React.FC = () => {
  return (
    <div className="header bg-pegasus">
      <div className="container header-container">
        <Link to="/home">
          <img src={pegasus} className="logo" alt="Logo Pegasus" />
        </Link>
        <div className="botones">
          <span>
            Soporte para <Link to="/home"><img src={academica} alt="Academica" /></Link> ACADEMICA WEB
          </span>
          <span className="dos"></span>
          <a href="https://web.colpegasus.com" target="_blank" rel="noopener noreferrer">
            <div className="btn1">Ir a colpegasus.com</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;