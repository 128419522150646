import React, { useEffect, useState, useContext } from "react";
import { FaAngleDoubleRight, FaEdit } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams, Link } from "react-router-dom";
import { getInfo } from "../services/infoService";
import { BsArrowRightShort } from "react-icons/bs";
import { AppContext } from "../AppContext";
import {
    Container,
    Typography,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import "../assets/css/Body.css";

interface Seccion {
    id_seccion: string;
    destacada: Number;
    number: number;
    seccion: string;
}

interface Vinculo {
    seccion: any;
    seccion_destacada: any;
    id: string;
    id_seccion: string;
    titulo: string;
}

const Body: React.FC = () => {
    const context = useContext(AppContext);
    const { hash } = useParams<{ hash: string }>();
    const [secciones, setSecciones] = useState<Seccion[]>([]);
    const [vinculos, setVinculos] = useState<Vinculo[]>([]);
    const [visibleSections, setVisibleSections] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSoporte, setIsSoporte] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let hashLocal = localStorage.getItem("hash");
                if (!hashLocal || (hash !== undefined && hash !== hashLocal)) {
                    localStorage.setItem("hash", hash as string);
                }

                const HASH = hash || hashLocal;

                if (context?.data.vinculos.length === 0) {
                    await getInfo(context, HASH);
                }

                setSecciones(context?.data.secciones || []);
                setVinculos(context?.data.vinculos || []);
                setIsSoporte(context?.data.isSoporte || false);

            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [hash, context]);

    const toggleSection = (id_seccion: string) => {
        setVisibleSections((prevVisibleSections) =>
            prevVisibleSections.includes(id_seccion)
                ? prevVisibleSections.filter((id) => id !== id_seccion)
                : [...prevVisibleSections, id_seccion]
        );
    };

    return (
        <Container className="body-container fadeIn">
            {isLoading ? (
                <CircularProgress />
            ) : (
                secciones.map((seccion) => (
                    <Accordion
                        key={seccion.id_seccion}
                        expanded={visibleSections.includes(seccion.id_seccion)}
                        onChange={() => toggleSection(seccion.id_seccion)}
                        style={{ borderRadius: "20px", marginBottom: 0, overflow: "hidden", marginTop: 0, boxShadow: "0px 10px 20px 0px #BBBBBB" }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${seccion.id_seccion}-content`}
                            id={`panel-${seccion.id_seccion}-header`}
                            style={{ borderBottom: "1px solid #BBBBBB" }}
                            className="accordion-summary"
                        >
                            {seccion.destacada === 1 && <BsArrowRightShort className="i" />}
                            <Typography variant="h6" className="text-pegasus" style={{ margin: 0 }}>
                                {seccion.destacada === 0 && <span>{seccion.number}. </span>}
                                {seccion.seccion}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {vinculos
                                    .filter((vinculo) => vinculo.id_seccion === seccion.id_seccion)
                                    .map((vinculo) => (
                                        <ListItem key={vinculo.id} style={{ borderBottom: 5 }} >
                                            <ListItemIcon style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                <Link to={`/link/${vinculo.id}`} className="link" style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                                                    <FaAngleDoubleRight className="i" />
                                                    <ListItemText primary={vinculo.titulo} />
                                                </Link>
                                                {isSoporte && (
                                                    <Link to={`/vinculos/${vinculo.id}`} className="link" style={{ marginLeft: "auto" }}>
                                                        <FaEdit className="i" />
                                                    </Link>
                                                )}
                                            </ListItemIcon>
                                        </ListItem>
                                    ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                ))
            )}
        </Container>
    );
};

export default Body;