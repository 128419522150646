import React, { useState, useEffect, useContext, useMemo } from "react";
import { BsLifePreserver, BsLightbulb, BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import "../assets/css/Search.css";

interface Info {
    perfil?: string;
    videos?: number;
    text2?: string;
}

const Search: React.FC = () => {
    const context = useContext(AppContext);

    const localData = localStorage.getItem("data");
    const localDataParsed = useMemo(() => {
        let parsedData: any = {};
        try {
            parsedData = JSON.parse(localData || "{}");
        } catch (error) {
            console.error('Error al analizar JSON:', error);
        }
        return parsedData;
    }, [localData]);

    const [info, setInfo] = useState<Info>({
        perfil: "",
        videos: 0,
        text2: "Vinculos de ayuda para los todos los perfiles",
    });
    const [typingTimer, setTypingTimer] = useState(0);
    const [text, setText] = useState("Escriba una frase o palabra clave de lo que busca");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {
        const onResize = () => {
            setWindowWidth(window.innerWidth);
            if (windowWidth > 600) {
                if (text !== "Escriba una frase o palabra clave de lo que busca") {
                    setText("Escriba una frase o palabra clave de lo que busca");
                }
            } else {
                if (text === "Escriba una frase o palabra clave de lo que busca") {
                    setText("Buscar");
                }
            }
        };

        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [text, windowWidth]);

    useEffect(() => {
        if (context) {
            setInfo({
                perfil: context?.data.perfil || localDataParsed?.perfil || "",
                videos: context?.data.vinculos > 0 ? context?.data.vinculos.length : localDataParsed?.vinculos?.length || 0,
                text2: context?.data.perfil || localDataParsed?.perfil ? "Vinculos de ayuda para el perfil" :"Vinculos de ayuda para los todos los perfiles",
            });
        }
    }, [context, localDataParsed]);

    const buscar = (termino: string) => {
        clearTimeout(typingTimer);
        setTypingTimer(
            window.setTimeout(() => {
                termino = termino.toLowerCase().replace(" ", "-");
                setText(termino);
                if (termino !== "") {
                    navigate(`/buscador/${termino}`);
                } else {
                    navigate("/");
                }
            }, 230)
        );
    };

    return (
        <div className="container search-container">
            <div className="info text-pegasus">
                <div className="info">
                    <BsLightbulb className="i lightbulb" />
                    <div className="row">
                        <span>Bienvenido a nuestro</span>
                        <span>Centro de conocimiento</span>
                    </div>
                </div>
                <div className="row descri">
                    <div><BsLifePreserver className="i" /> ({info?.videos}) {info?.text2}</div>
                    <div>{info?.perfil}</div>
                </div>
            </div>
            <div className="search">
                <div className="buscar">
                    <BsSearch className="i" />
                    <input
                        type="text"
                        placeholder={text ? text : "Escriba una frase o palabra clave de lo que busca"}
                        onClick={(e) => buscar((e.target as HTMLInputElement).value)}
                        onKeyUp={(e) => buscar((e.target as HTMLInputElement).value)}
                        className="search-input"
                    />
                </div>
                <p>Para resultados más precisos ingrese palabras claves de lo que quiere hacer, por ejemplo:</p>
                <p>
                    (Como calificar) valoraciones, definitivas, (Generar listados de asistencia) listado de asistencia. (Como ver calificaciones) calificaciones, notas, valoraciones.
                </p>
            </div>
        </div>
    );
};

export default Search;