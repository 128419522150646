import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Answer from './components/Answer';
import Body from './components/Body';
import View from './components/View';
import Layout from './Layout';
import NotFound from './components/NotFound';
import Vinculos from './components/Vinculos';
import 'jquery'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route index element={<Body />} />
        <Route path="home" element={<Body />} />
        <Route path="home/:hash" element={<Body />} />
        <Route path="home/:hash/link/:link" element={<Body />} />
        <Route path="buscador/:termino" element={<Answer />} />
        <Route path="link/:id" element={<View />} />
      <Route path="vinculos/:id" element={<Vinculos />} />
      </Route>
      <Route path="*" element={<NotFound />} /> {/* Ruta de "catch-all" */}
    </>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();