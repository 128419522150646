import React, { useRef, useEffect, useState, MouseEvent } from 'react';
import { FaBold, FaItalic, FaUnderline, FaStrikethrough, FaListOl, FaListUl, FaAlignLeft, FaAlignCenter, FaAlignRight, FaAlignJustify, FaLink, FaUnlink, FaImage, FaEraser, FaEdit, FaVideo } from 'react-icons/fa';
import { Button, ButtonGroup, Box, Menu, MenuItem } from '@mui/material';
import editorLabels from '../assets/json/editorLabels.json';
import Swal from 'sweetalert2';
import '../assets/css/EditorComponent.css';

interface WysiwygEditorProps {
    value: string;
    onChange: (value: string) => void;
    options: string[];
}

const WysiwygEditor: React.FC<WysiwygEditorProps> = ({ value = '', options = [], onChange }) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const [optionsActive, setOptionsActive] = useState<string[]>([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedVideo, setSelectedVideo] = useState<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (editorRef.current && editorRef.current.innerHTML !== value) {
            editorRef.current.innerHTML = value;
        }
    }, [value]);

    useEffect(() => {
        const handleSelectionChange = () => {
            const newOptionsActive = options.filter(option => document.queryCommandState(option));
            setOptionsActive(newOptionsActive);
        };

        document.addEventListener('selectionchange', handleSelectionChange);
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, [options]);

    const handleInput = () => {
        if (editorRef.current) {
            const newValue = editorRef.current.innerHTML;
            if (newValue !== value) {
                onChange(newValue);
            }
        }
    };

    const execCommand = (command: string, value: string = '') => {
        document.execCommand(command, false, value);
    };

    const handleVideoClick = async (video: HTMLVideoElement) => {
        const { value: url, isConfirmed } = await Swal.fire({
            title: 'Cambiar URL del video',
            input: 'url',
            inputPlaceholder: 'http://',
            inputValue: video.src,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            allowEscapeKey: false,
            inputValidator: (value) => {
                if (!value) {
                    return 'Debes ingresar una URL';
                }
            },
            inputAttributes: {
                'aria-label': 'URL'
            }
        });

        if (!isConfirmed) return;

        const isVideoUrl = (url: string) => {
            const videoExtensions = ['.mp4', '.webm', '.ogg'];
            return videoExtensions.some(ext => url.endsWith(ext));
        };

        if (url && isVideoUrl(url)) {
            video.src = url;
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'La URL proporcionada no es un enlace de video válido.',
            });
        }
    };

    const handleEditHtml = async () => {
        const { value: html, isConfirmed } = await Swal.fire({
            title: 'Editar HTML',
            input: 'textarea',
            inputValue: editorRef.current?.innerHTML,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            allowEscapeKey: false,
            inputValidator: (value) => {
                if (!value) {
                    return 'El contenido no puede estar vacío';
                }
            },
            inputAttributes: {
                'aria-label': 'HTML',
            },
            customClass: {
                container: 'textarea-editor-container',
                input: 'textarea-editor',

            },
        });

        if (isConfirmed && editorRef.current) {
            editorRef.current.innerHTML = html;
            onChange(html);
        }
    };

    const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const target = event.target as HTMLElement;
        if (target.tagName === 'VIDEO') {
            setSelectedVideo(target as HTMLVideoElement);
            setMenuAnchorEl(target);
        }
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedVideo(null);
    };

    const handleMenuOptionClick = async (option: string) => {
        if (option === 'changeUrl' && selectedVideo) {
            await handleVideoClick(selectedVideo);
        } else if (option === 'remove' && selectedVideo) {
            selectedVideo.remove();
        }
        onChange(editorRef.current?.innerHTML || '');
        handleMenuClose();
    };

    return (
        <Box sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2 }} className="editor-component">
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: 1 }}>
                <ButtonGroup aria-label="">
                    {options.map((command) => (
                        <Button
                            key={command}
                            onMouseDown={async (e) => {
                                e.preventDefault();

                                setOptionsActive([command]);

                                if (['insertVideo', 'insertImage'].includes(command)) {
                                    const { value: url } = await Swal.fire({
                                        title: `Ingresar la ${command === 'insertVideo' ? 'URL' : command === 'insertImage' ? 'URL de la imagen' : 'URL del video'}`,
                                        input: 'url',
                                        inputPlaceholder: 'http://',
                                        showCancelButton: true,
                                        confirmButtonText: 'Guardar',
                                        cancelButtonText: 'Cancelar',
                                        allowEscapeKey: false,
                                        inputValidator: (value) => {
                                            if (!value) {
                                                return 'Debes ingresar una URL';
                                            }
                                        },
                                        inputAttributes: {
                                            'aria-label': 'URL'
                                        }
                                    });

                                    if (!url) {
                                        setOptionsActive([]);
                                        return;
                                    };

                                    if (command === 'insertVideo') {
                                        if (editorRef.current) {
                                            editorRef.current.innerHTML += `<video controls autoplay src="${url}" width="100%" height="auto">Lo sentimos, su navegador no admite videos incrustados.</video>`;
                                        }
                                    } else {
                                        if (editorRef.current) {
                                            const img = new Image();
                                            img.src = url;
                                            img.onload = () => {
                                                editorRef.current!.innerHTML += `<img src="${url}" alt="Imagen" width="100%" height="auto" />`;
                                            };
                                            img.onerror = () => {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Oops...',
                                                    text: 'La URL de la imagen no es válida.',
                                                });
                                            };
                                        }
                                    }
                                    setOptionsActive([]);
                                    return;
                                } else if (command === 'createLink') {
                                    const selection = window.getSelection();
                                    if (!selection || selection.rangeCount === 0 || selection.isCollapsed) {
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'Selecciona texto',
                                            text: 'Por favor, selecciona el texto que deseas enlazar.',
                                        });
                                        setOptionsActive([]);
                                        return;
                                    }

                                    const { value: url, isConfirmed } = await Swal.fire({
                                        title: 'Insertar enlace',
                                        input: 'url',
                                        inputPlaceholder: 'http://',
                                        showCancelButton: true,
                                        confirmButtonText: 'Insertar',
                                        cancelButtonText: 'Cancelar',
                                        allowEscapeKey: false,
                                        inputValidator: (value) => {
                                            if (!value) {
                                                return 'Debes ingresar una URL';
                                            }
                                        },
                                        inputAttributes: {
                                            'aria-label': 'URL'
                                        }
                                    });

                                    if (!isConfirmed) {
                                        setOptionsActive([]);
                                        return;
                                    }

                                    execCommand(command, url);
                                }
                                execCommand(command);
                            }}
                            sx={{ backgroundColor: optionsActive.includes(command) ? 'rgba(6, 82, 221, 1);' : '', color: optionsActive.includes(command) ? 'white' : 'rgba(6, 82, 221, 1)' }}
                            title={editorLabels[command as keyof typeof editorLabels] || ''}
                        >
                            {command === 'bold' && <FaBold />}
                            {command === 'italic' && <FaItalic />}
                            {command === 'underline' && <FaUnderline />}
                            {command === 'strikeThrough' && <FaStrikethrough />}
                            {command === 'insertOrderedList' && <FaListOl />}
                            {command === 'insertUnorderedList' && <FaListUl />}
                            {command === 'justifyLeft' && <FaAlignLeft />}
                            {command === 'justifyCenter' && <FaAlignCenter />}
                            {command === 'justifyRight' && <FaAlignRight />}
                            {command === 'justifyFull' && <FaAlignJustify />}
                            {command === 'insertVideo' && <FaVideo />}
                            {command === 'createLink' && <FaLink />}
                            {command === 'unlink' && <FaUnlink />}
                            {command === 'insertImage' && <FaImage />}
                            {command === 'removeFormat' && <FaEraser />}
                        </Button>
                    ))}
                    <Button
                        onMouseDown={(e) => {
                            e.preventDefault();
                            handleEditHtml();
                        }}
                        title='Editar HTML'
                    >
                        <FaEdit />
                    </Button>
                </ButtonGroup>
            </Box>
            <Box
                ref={editorRef}
                contentEditable
                className="editor"
                onInput={handleInput}
                sx={{ minHeight: '200px', padding: 4 }}
                onContextMenu={handleContextMenu}
            />
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleMenuClose()
                    handleMenuOptionClick('changeUrl')
                }}>
                    Cambiar URL del video
                </MenuItem>
                <MenuItem onClick={() => {
                    handleMenuClose()
                    handleMenuOptionClick('remove')
                }}>
                    Eliminar video
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default WysiwygEditor;