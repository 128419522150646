import React, { createContext, useState, ReactNode } from 'react';

interface AppContextProps {
  data: any;
  setData: (data: any) => void;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children?: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [data, setData] = useState<any>({
    vinculos: [],
    perfil: "",
    secciones: [],
    isSoporte: false,
  });

  return (
    <AppContext.Provider value={{ data, setData }}>
      {children}
    </AppContext.Provider>
  );
};