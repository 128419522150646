import { Link } from "react-router-dom";
import "../assets/css/Footer.css";

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer-container">
            <div className="terminos">
                <Link to="https://web.colpegasus.com/proteccion-datos/index.html" target="_blank">
                    <span>Términos de uso </span> - <span>Política de privacidad</span>
                </Link>
            </div>
            <div className="copy">
                <span>© {currentYear} | Pegasus Software.</span> Todos los derechos reservados
            </div>
        </div>
    );
};

export default Footer;