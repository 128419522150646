import React, { useContext, useEffect, useState } from "react";
import { FaAngleDoubleRight, FaArrowRight, FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../assets/css/Answer.css";
import { AppContext } from "../AppContext";
import { getInfo, sendVisit } from "../services/infoService";
import _ from "lodash";

interface Vinculo {
    index: number;
    id: string;
    id_seccion: number;
    numero_visitas: number;
    orden: number;
    seccion: string;
    seccion_destacada: number;
    titulo: string;
    vinculo_publico: string;
    vinculo_rapido: string;
}

const Answer: React.FC<{}> = () => {
    const context = useContext(AppContext);

    const localData = localStorage.getItem("data");
    const localDataParsed = JSON.parse(localData || "");


    const [vinculos, setVinculos] = useState<Vinculo[]>(context?.data.vinculos > 0 ? context?.data.vinculos : localDataParsed.vinculos || []);
    const { termino } = useParams<{ termino: string }>();
    const [numAns, setNumAns] = useState<any>(0);
    const [answer, setAnswer] = useState<any>([]);
    const hash = localStorage.getItem("hash") || "";

    useEffect(() => {
        const fetchData = async () => {
            if (termino) {
                setAnswer(search(termino));
            }

            await getInfo(context, hash)
            setVinculos(context?.data.vinculos || []);
        }
        fetchData();

    }, [termino]);

    const search = (termino: string) => {
        let search: { id: string; index: number }[] = [];
        let dict: { [key: string]: string } = { "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u" };
        setNumAns(0);

        termino = termino.replace(/[^\w ]/g, function (char: string) {
            return dict[char] || char;
        });

        _.forEach(vinculos, (element, index) => {
            let nombre = element.titulo.toLowerCase();
            nombre = nombre.replace(/[^\w ]/g, function (char) {
                return dict[char] || char;
            });
            nombre = nombre.replace(" ", "-");
            if (nombre.indexOf(termino.toLowerCase()) >= 0) {
                search.push(element);
                search.push({ id: element.id, index });
                setNumAns((prevNumAns: number) => prevNumAns + 1);
            }
        });
        return search;
    }

    return (
        <div className="answer-container fadeIn">
            <div className="view">
                <div className="migas">
                    <Link to={"/home"}>
                        <a className="text-pegasus"><FaChevronLeft className="i" />Volver al Inicio</a>
                    </Link>
                    <div><FaAngleDoubleRight className="i" size={12} /> Resultados de la busqueda </div>
                </div>
            </div>
            <div className="viewV">
                <h4>{numAns} resultados para <span>{termino}</span></h4>
                <ul>
                    {answer.map((vinc: { index: number; id: any; }, index: React.Key | null | undefined) => (
                        <li key={index}>
                            {vinculos[vinc.index]?.titulo && (
                                <Link to={`/link/${vinc.id}`}>
                                    {vinculos[vinc.index].id_seccion === 0 ? (
                                        <FaArrowRight className="i text-pegasus" />
                                    ) : (
                                        <>
                                            <FaAngleDoubleRight className='i text-pegasus' />
                                            <span className="text-pegasus">{vinculos[vinc.index].id_seccion}.</span>
                                        </>
                                    )}
                                    <span className="text-pegasus">{vinculos[vinc.index]?.seccion}</span> {vinculos[vinc.index]?.titulo}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Answer;