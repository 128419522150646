import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { converHtml, getInfo, sendRequest } from '../services/infoService';
import withReactContent from 'sweetalert2-react-content';
import { FaChevronLeft, FaSave } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import EditorComponent from './EditorComponent';
import { AppContext } from '../AppContext';
import '../assets/css/Vinculos.css';
import Swal from 'sweetalert2'
import _ from 'lodash';

const MySwal = withReactContent(Swal)

interface Vinculo {
    contenido: any;
    seccion: any;
    seccion_destacada: any;
    id: string;
    id_seccion: string;
    titulo: string;
    vinculo_rapido: string;
}

interface Seccion {
    id_seccion: string;
    seccion: string;
}

const Vinculos: React.FC = () => {
    const context = useContext(AppContext);
    const { id } = useParams<{ id: string }>();

    const localData = localStorage.getItem("data");
    const localDataParsed = JSON.parse(localData || '');

    const [vinculos, setVinculos] = useState<Vinculo[]>((context?.data.vinculos.length > 0) ? context?.data.vinculos : localDataParsed?.vinculos || []);
    const [vinculo, setVinculo] = useState<Vinculo | null>(null);
    const [request, setRequest] = useState(false);
    const [secciones] = useState<Seccion[]>((context?.data.secciones.length > 0) ? context?.data.secciones : localDataParsed?.secciones || []);
    const [selectedSeccion, setSelectedSeccion] = useState(vinculo?.id_seccion || '');
    const [selectedRapido, setSelectedRapido] = useState(vinculo?.vinculo_rapido || '');
    const [content, setContent] = useState(vinculo?.contenido || '');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const currentVinculo = vinculos.find((element) => Number(element.id) === Number(id));

            if (currentVinculo) {
                setVinculo(currentVinculo);

                if (!currentVinculo.contenido && currentVinculo.contenido !== "" && !request && currentVinculo.id) {
                    setRequest(true);
                    let formData = new FormData();
                    formData.append("idContenido", currentVinculo.id);
                    sendRequest(formData)
                        .then(data => {
                            let contentHtml = converHtml(data);
                            setContent(contentHtml);
                            const updatedVinculo = { ...currentVinculo, contenido: contentHtml };
                            setVinculo(updatedVinculo);
                            const updatedVinculos = vinculos.map(v => v.id === updatedVinculo.id ? updatedVinculo : v);
                            setVinculos(updatedVinculos);
                            setRequest(false);
                            setLoading(false); // Datos cargados, ocultar loader
                        }).catch(() => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al cargar el contenido',
                                text: 'Ha ocurrido un error al cargar el contenido del vínculo',
                            });
                            setLoading(false); // Datos cargados, ocultar loader
                            return;
                        });
                    return;
                }
                setContent(currentVinculo.contenido);
                setLoading(false); // Datos cargados, ocultar loader
            }
        }
        fetchData();
    }, [id, vinculos, request]);

    useEffect(() => {
        setSelectedSeccion(vinculo?.id_seccion || '');
        setSelectedRapido(vinculo?.vinculo_rapido || '');
    }, [vinculo]);

    const handleChange = (event: any) => {
        setSelectedSeccion(event.target.value);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (vinculo) {
            setVinculo({ ...vinculo, titulo: event.target.value });
        }
    };

    const handleFastChange = (event: any) => {
        setSelectedRapido(event.target.value);
    }

    const handleEditorChange = (content: string) => {
        setContent(content);
    };

    const handleSave = () => {
        if (vinculo) {
            const updatedVinculo = { ...vinculo, contenido: content, id_seccion: selectedSeccion, vinculo_rapido: selectedRapido };
            const data = (context?.data.vinculos.length > 0) ? context?.data.vinculos : localDataParsed?.vinculos || [];

            const dataOld = data.find((v: { id: Number; }) => Number(v.id) === Number(vinculo.id));

            const hasChanged = _.isEqual(updatedVinculo, dataOld);

            if (hasChanged) {
                Swal.fire({
                    icon: 'info',
                    title: 'Sin cambios',
                    text: 'No se han realizado cambios en el contenido',
                });
                return;
            }

            const formData = new FormData();
            formData.append("guardar_vinculo", "true");
            formData.append("id", updatedVinculo.id);
            formData.append("id_seccion", updatedVinculo.id_seccion);
            formData.append("titulo", updatedVinculo.titulo);
            formData.append("vinculo_rapido", updatedVinculo.vinculo_rapido);
            formData.append("contenido", updatedVinculo.contenido);

            setLoading(true);

            sendRequest(formData).then(async () => {
                await getInfo(context, localStorage.getItem("hash"));
                setLoading(false);
                MySwal.fire({
                    icon: 'success',
                    title: 'Vínculo actualizado',
                    text: 'El vínculo ha sido actualizado correctamente',
                });
            }).catch((error) => {
                console.error(error);
                setLoading(false);
                MySwal.fire({
                    icon: 'error',
                    title: 'Error al actualizar',
                    text: 'Ha ocurrido un error al actualizar el vínculo',
                });
            });
        }
    };

    return (
        <div className="vinculos-container">
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div className="view">
                        <div className="migas">
                            <Link to="/home" >
                                <FaChevronLeft className="i" style={{ marginRight: '5px' }} />
                                Volver al Inicio
                            </Link>
                        </div>
                    </div>

                    <h2>Editar Vínculo "{vinculo?.titulo || ''}"</h2>

                    <div className="vinculos" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="Sección">Sección</InputLabel>
                                <Select
                                    labelId="Sección"
                                    id="select"
                                    value={selectedSeccion}
                                    label="Sección"
                                    onChange={handleChange}
                                >
                                    {secciones.map((seccion) => (
                                        <MenuItem key={seccion.id_seccion} value={seccion.id_seccion}>{seccion.seccion}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <TextField id="titulo" label="Titulo" variant="outlined" value={vinculo?.titulo || ''} fullWidth onChange={handleTitleChange} />
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="vinculo-rapido">¿Vinculo rápido?</InputLabel>
                                <Select
                                    labelId="vinculo-rapido"
                                    id="vinculo-rapido"
                                    value={selectedRapido}
                                    label="¿Vinculo rápido?"
                                    onChange={handleFastChange}
                                >
                                    <MenuItem key={"TRUE"} value={"TRUE"}>Si</MenuItem>
                                    <MenuItem key={"FALSE"} value={"FALSE"}>No</MenuItem>

                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <h3>Contenido</h3>
                            <div style={{ marginBottom: '20px' }}>
                                <EditorComponent
                                    options={[
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strikeThrough',
                                        'insertOrderedList',
                                        'insertUnorderedList',
                                        'justifyLeft',
                                        'justifyCenter',
                                        'justifyRight',
                                        'justifyFull',
                                        'insertVideo',
                                        'createLink',
                                        'unlink',
                                        'insertImage',
                                        'removeFormat'
                                    ]}
                                    value={content}
                                    onChange={handleEditorChange}
                                />
                            </div>
                        </div>
                        <div>
                            <Button variant="contained" onClick={handleSave}>
                                Guardar
                                <FaSave style={{ marginLeft: '5px' }} />
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Vinculos;