import React, { useContext, useEffect, useState } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaChevronLeft, FaEye, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { converHtml, sendVisit } from '../services/infoService';
import DOMPurify from 'dompurify';
import "../assets/css/View.css";
import { AppContext } from '../AppContext';

interface Vinculo {
    seccion: string;
    seccion_destacada: string;
    id: string;
    id_seccion: string;
    titulo: string;
    numero_visitas: number;
    contenido: string;
}

const View: React.FC = () => {
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const localData = localStorage.getItem("data");
    const localDataParsed = JSON.parse(localData || '');
    const info = context?.data?.vinculos.length > 0 ? context?.data.vinculos : localDataParsed?.vinculos || [];
    const { id } = useParams<{ id: string }>();
    const [vinculos, setVinculos] = useState<Vinculo[]>(info);
    const [vinculo, setVinculo] = useState<Vinculo | null>(null);
    const [vinculo2, setVinculo2] = useState<Vinculo | null>(null);
    const [vinculo3, setVinculo3] = useState<Vinculo | null>(null);
    const [send, setSend] = useState(false);
    const [thim1, setThim1] = useState(false);
    const [thim2, setThim2] = useState(false);
    const [request, setRequest] = useState(false);

    useEffect(() => {
        if (vinculos.length === 0 || request) return;

        const currentVinculo = vinculos.find((element) => Number(element.id) === Number(id));
        if (currentVinculo) {
            setVinculo(currentVinculo);

            if (!currentVinculo.contenido && !request && currentVinculo.id) {
                setRequest(true);
                let formData = new FormData();
                formData.append("idContenido", currentVinculo.id);
                fetch("https://ayuda.colpegasus.org/php/cliente.php", {
                    method: "POST",
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        const updatedVinculo = { ...currentVinculo, contenido: converHtml(data.resp), numero_visitas: currentVinculo.numero_visitas + 1 };
                        setVinculo(updatedVinculo);

                        const updatedVinculos = vinculos.map(v => v.id === updatedVinculo.id ? updatedVinculo : v);
                        setVinculos(updatedVinculos);

                        localStorage.setItem("data", JSON.stringify({
                            vinculos: updatedVinculos,
                            perfil: context?.data.perfil,
                            secciones: context?.data.secciones,
                            isSoporte: context?.data.isSoporte,
                        }));

                        context?.setData((prevData: any) => ({
                            ...prevData,
                            vinculos: updatedVinculos,
                        }));

                        sendVisit(currentVinculo.id, "numero_visitas");

                        setRequest(false);
                    }).catch(() => {
                        setRequest(false);
                    });
            }

            const currentIndex = vinculos.indexOf(currentVinculo);
            const prevIndex = (currentIndex === 0) ? vinculos.length - 1 : currentIndex - 1;
            const nextIndex = (currentIndex === vinculos.length - 1) ? 0 : currentIndex + 1;

            setVinculo2(vinculos[prevIndex]);
            setVinculo3(vinculos[nextIndex]);
        }
    }, [id, context, vinculos]);

    const llegada = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let href = (event.target as HTMLElement).getAttribute('href');
        if (href) {
            navigate(href);
            return true;
        }
        return false;
    };

    const sendVotePos = () => {
        setThim1(true);
        setThim2(false);
        setSend(true);
        sendVisit(vinculo?.id || '', "voto_positivo");
    };

    const sendVoteNeg = () => {
        setThim1(false);
        setThim2(true);
        setSend(true);
        sendVisit(vinculo?.id || '', "voto_negativo");
    };

    const sanitizedData = (data: string) => ({
        __html: DOMPurify.sanitize(data)
    });

    if (!vinculo) return (
        <div className="view-container fadeIn">
            <div className="view">
                <div className="migas">
                    <Link to="/home"><FaChevronLeft className="i" />Volver al Inicio</Link>
                </div>
            </div>
            <span>No se encontro la información del vinculo</span>
        </div>
    );

    return (
        <div className="view-container fadeIn">
            <div className="view">
                <div className="migas">
                    <Link to="/home"><FaChevronLeft className="i" />Volver al Inicio</Link>
                    <div><FaAngleDoubleRight className="i" />  {vinculo?.seccion} </div>
                </div>
                <div className="num"><FaEye className="i" /> Visto {vinculo.numero_visitas} veces</div>
            </div>
            <div className="viewV">
                <h1 className="text-pegasus">{vinculo?.titulo}</h1>
                <div className="contenido" dangerouslySetInnerHTML={sanitizedData(vinculo?.contenido || '')} onClick={llegada} />
                <div className="footer">
                    <div className="enlaces">
                        <div className="enlace">
                            <div>
                                <FaAngleDoubleLeft className="i" />
                                {vinculo2?.id_seccion !== '0' && <span>{vinculo2?.id_seccion}.</span>}{vinculo2?.seccion}
                            </div>
                            <Link to={`/link/${vinculo2?.id}`}>{vinculo2?.titulo}</Link>
                        </div>
                        <div className="enlace">
                            <div>
                                {vinculo3?.id_seccion !== '0' && <span>{vinculo3?.id_seccion}.</span>}{vinculo3?.seccion}
                                <FaAngleDoubleRight className="i" />
                            </div>
                            <Link to={`/link/${vinculo3?.id}`}>{vinculo3?.titulo}</Link>
                        </div>
                    </div>
                    <div className={`like fadeIn ${send ? 'hidden' : ''}`}>
                        <h4 className="text-pegasus">¿Le resultó útil ésta información?</h4>
                        <div className="iconos">
                            <FaThumbsUp className={`${thim1 ? 'text-pegasus' : 'text-down'} i`} onClick={sendVotePos} />
                            <FaThumbsDown className={`${thim2 ? 'text-pegasus' : 'text-down'} i`} onClick={sendVoteNeg} />
                        </div>
                    </div>
                    {send && <div className="fadeIn tenks text-pegasus">¡Gracias por su opinión!</div>}
                </div>
            </div>
        </div>
    );
};

export default View;