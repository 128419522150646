import React, { useState, useEffect, useContext } from "react";
import { FaBook, FaEye, FaRegCircle } from "react-icons/fa";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppContext } from "../AppContext";
import { BsCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Box
} from '@mui/material';
import "../assets/css/Aside.css";

interface Vinculo {
    id: string;
    id_seccion: string;
    numero_visitas: string;
    orden: string;
    seccion: string;
    seccion_destacada: string;
    titulo: string;
    vinculo_publico: string;
    vinculo_rapido: string;
}

interface VinculoPopul {
    data: string;
    index: number;
}

const Aside: React.FC = () => {
    const context = useContext(AppContext);
    const [mostrar, setMostrar] = useState(true);
    const [mostrar2, setMostrar2] = useState(true);
    const [vinculos, setVinculos] = useState<Vinculo[]>([]);
    const [vinculosPopul, setVinculosPopul] = useState<VinculoPopul[]>([]);
    const [popul, setPopul] = useState(0);

    const onResize = () => {
        if (window.innerWidth < 800) {
            setMostrar(false);
            setMostrar2(false);
        } else {
            setMostrar(true);
            setMostrar2(true);
        }
    };

    useEffect(() => {
        const sendVinculos = () => {
            return new Promise<Vinculo[]>((resul) => {
                let localData = localStorage.getItem("data");
                let localDataParsed = JSON.parse(localData || '');
                let info = (context?.data.vinculos.length > 0) ? context?.data.vinculos : localDataParsed?.vinculos || [];

                if (info) {
                    resul(info);
                }
            });
        };

        const getVinculosPopul = (vinculos: Vinculo[]) => {
            const sortedVinculos = vinculos
                .map((element) => ({
                    ...element,
                    numero_visitas: parseInt(element.numero_visitas),
                }))
                .sort((a, b) => b.numero_visitas - a.numero_visitas);

            return sortedVinculos.slice(0, 5).map((vinculo, index) => ({
                data: vinculo.id,
                index: index
            }));
        };

        const getVincFast = (vinculos: Vinculo[]) => {
            let i = 0;
            vinculos.forEach((element) => {
                if (element.vinculo_rapido === "TRUE") {
                    i++;
                }
            });
            return i;
        };

        sendVinculos().then((data) => {
            setVinculos(data);
            setVinculosPopul(getVinculosPopul(data));
            setPopul(getVincFast(data));
        });

        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [context]);

    return (
        <Box className="aside fadeIn">
            <Accordion
                expanded={mostrar}
                onChange={() => setMostrar(!mostrar)}
                style={{ borderRadius: "20px", marginBottom: "10px", overflow: "hidden", marginTop: 0, boxShadow: "0px 10px 20px 0px #e0e0e0", backgroundColor: "#E6EDEF" }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ borderBottom: "1px solid #BBBBBB" }}
                    className="accordion-summary"
                >
                    <Typography variant="h6" className="text-pegasus">
                        <FaBook className="i" />
                        Vinculos rápidos
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {vinculos.map((vinculo) => (
                            vinculo.vinculo_rapido === "TRUE" && (
                                <ListItem key={vinculo.id} component={Link} to={`/link/${vinculo.id}`}>
                                    <ListItemIcon>
                                        <BsCircle className="text-pegasus i" />
                                    </ListItemIcon>
                                    <ListItemText primary={vinculo.titulo} />
                                </ListItem>
                            )
                        ))}
                        <ListItem component="a" href="http://blog.colpegasus.com/" target="_blank">
                            <ListItemIcon>
                                <BsCircle className="text-pegasus i" />
                            </ListItemIcon>
                            <ListItemText primary="Blog de Pegasus Software" className="text-list" />
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
            {popul > 0 && (
                <Accordion
                    expanded={mostrar2}
                    onChange={() => setMostrar2(!mostrar2)}
                    style={{ borderRadius: "20px", marginBottom: "10px", overflow: "hidden", marginTop: 0, boxShadow: "0px 10px 20px 0px #e0e0e0" }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ borderBottom: "1px solid #BBBBBB" }}
                        className="accordion-summary">
                        <Typography variant="h6" className="text-pegasus">
                            <FaEye className="i" />
                            Vinculos populares
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {vinculosPopul.map((vinc, index) => (
                                <ListItem key={index} component={Link} to={`/link/${vinc.data}`}>
                                    <ListItemIcon>
                                        <FaRegCircle className="text-pegasus i" />
                                    </ListItemIcon>
                                    <ListItemText primary={vinculos[vinc.index].titulo} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
};

export default Aside;
