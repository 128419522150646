import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Search from './components/Search';
import Aside from './components/Aside';
import { AppProvider } from './AppContext';

const Layout: React.FC = () => {
  return (
    <AppProvider>
      <div>
        <Header />
        <Search />
        <main className="container flex">
          <Aside />
          <Outlet />
        </main>
        <Footer />
      </div>
    </AppProvider>
  );
};

export default Layout;